.dialog-box {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-mask-image: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 1) 40%,
    rgba(0, 0, 0, 0) 100%
  );
  mask-image: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 1) 40%,
    rgba(0, 0, 0, 0) 100%
  );
  mask-size: 100% 100%;
  mask-repeat: no-repeat;
  @apply top-0 lg:top-[80px] w-full lg:w-[220px] min-h-[120px] lg:min-h-[90px] p-6 text-gray-300 absolute lg:-left-[220px];
}

.fade-in {
  animation: fade-in 0.5s forwards;
}

.fade-out {
  animation: fade-out 1.5s forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
